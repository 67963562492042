import { createAction, props } from '@ngrx/store';
import { PolicyHolder } from '@interfaces/policyholder';
import { AddressSearchResponse } from '@shared/interfaces/address-search-response.interface';
import { Policy } from '@interfaces/policy';
import { ClientDetailsEnum } from '@features/account-details/enums/client-details.enum';
import { NewAddress } from '@features/account-details/components/Interfaces/new-address.interface';
import { 
  RetrieveAddressResult, 
} from '@features/account-details/components/Interfaces/address-retrieve-result.interface';
import { 
  ClientPremiumRecalculation, 
} from '@features/policies/interfaces/client-premium-recalculation.interface';
import { ClientRestrictions } from '@features/account-details/interfaces/client-restrictions.interface';
import { ClientConsents } from '@features/account-details/interfaces/client-consents.interface';
import { ExtraSupportQuestionAnswers } from '@features/account-details/interfaces/extra-support-question-answers';
import { ExtraSupportQuestionAnswer } from '@features/account-details/interfaces/extra-support-question-answer';

const setPolicyHolder = createAction(
  '[Policy Holder] Set Policy Holder',
  props<{ policyHolder: PolicyHolder }>(),
);

const fetchPolicySummary = createAction('[Policy Holder] Fetch Policy Summary');

const fetchPolicyHolder = createAction('[Policy Holder] Fetch Policy Holder');

const fetchPolicySummarySuccess = createAction(
  '[Policy Holder] Fetch Policy Summary Success',
  props<{
    policyHolder: PolicyHolder,
    activePolicies: Policy[],
  }>(),
);

const fetchPolicySummaryFailure = createAction(
  '[Policy Holder] Fetch Policy Summary Failure',
);

const updatePhoneNumbers = createAction(
  '[Policy Holder] Update Phone Numbers',
  props<{ mobileNumber: string, eveningNumber: string }>(),
);

const updatePhoneNumbersSuccess = createAction(
  '[Policy Holder] Update Phone Numbers Success',
  props<{ policyHolder: PolicyHolder }>(),
);

const updatePhoneNumbersError = createAction(
  '[Policy Holder] Update Phone Numbers Error',
);

const searchAddress = 
  createAction('[Address] Search address', props<{ text: string; container?: string }>());
const searchAddressSuccess =
  createAction(
    '[Address] Search address success',
    props<{ addressSearchResponse: AddressSearchResponse[] }>(),
  );

const retrieveAddress = createAction(
  '[Policy Holder] Retrieve Address',
  props<{ addressId: string }>(),
);

const retrieveAddressSuccess = createAction(
  '[Policy Holder] Retrieve Address Success',
  props<{ retrieveAddressResult: RetrieveAddressResult }>(),
);

const editingField = createAction(
  '[Policy Holder] Start editing field', props<{ field: ClientDetailsEnum | null }>(),
);

const editingPhoneField = createAction(
  '[Policy Holder] Start editing phone field',
);
const editAddressSelected = createAction(
  '[Policy Holder] Edit address selected',
);

const addressTypeahead = createAction(
  '[Policy Holder] Start searching for address',
);

const addressManual = createAction(
  '[Policy Holder] Start manually entering address',
);

const britishForcesPostcodeSelected = createAction(
  '[Policy Holder] British Forces Postcode Selected',
);

const changeNotAllowed = createAction(
  '[Policy Holder] Change is not allowed',
);

const changeNotAllowedError = createAction(
  '[Policy Holder] Address change not allowed error',
);

const recalculatePremium = createAction(
  '[Policy Holder] Recalculate premium',
  props<{ newAddress: NewAddress }>(),
);

const recalculationSuccess = createAction(
  '[Policy Holder] Recalculate premium success',
  props<{ premiumRecalculation: ClientPremiumRecalculation[] }>(),
);

const recalculationError = createAction(
  '[Policy Holder] Recalculate premium error',
);

const updateAddress = createAction(
  '[Policy Holder] Update Address',
  props<{ newAddress: NewAddress }>(),
);

const updateAddressSuccess = createAction(
  '[Policy Holder] Update Address Success',
  props<{ newAddress: NewAddress }>(),
);

const updateAddressError = createAction(
  '[Policy Holder] Update Address Error',
);

const addressChangeCancelled = createAction(
  '[Policy Holder] Address Change Cancelled',
  props<{ editedAddress: NewAddress }>(),
);

const addressSearchResultSelected = createAction(
  '[Policy Holder] Address Search Result Selected',
  props<{ addressSearchResponse: AddressSearchResponse }>(),
);

const resetEditingField = createAction(
  '[Policy Holder] Reset editing field',
);

const accountDetailsPageLoaded = 
  createAction('[Account Details] Account Details Page Loaded');

const manualAddressFormSubmitted = createAction(
  '[Policy Holder] Manually entered address form submitted',
);

const fetchPersonalDetailsSuccess = createAction(
  '[Policy Holder] Fetch Personal Details Success',
  props<{isJoiiRegistered: boolean}>(),
);

const fetchPersonalDetailsFailure = createAction(
  '[Policy Holder] Fetch Personal Details Failure',
);

const fetchRestrictionsSuccess = createAction(
  '[Policy Holder] Fetch Restrictions Success',
  props<{ restrictions: ClientRestrictions }>(),
);

const fetchRestrictionsError = createAction(
  '[Policy Holder] Fetch Restrictions Error',
);

const fetchConsents = createAction(
  '[Policy Holder] Fetch Consents',
);

const fetchConsentsSuccess = createAction(
  '[Policy Holder] Fetch Consents Success',
  props<{ consents: ClientConsents }>(),
);

const fetchConsentsError = createAction(
  '[Policy Holder] Fetch Consents Error',
);

const patchConsents = createAction(
  '[Policy Holder] Update Consent',
  props<{consents: ClientConsents}>(),
);

const patchConsentsSuccess = createAction(
  '[Policy Holder] Update Consent Success',
  props<{consents: ClientConsents}>(),
);

const patchConsentsFailure = createAction(
  '[Policy Holder] Update Consent Failure',
);

const remotePrescribingConsentFormLoaded = createAction(
  '[Remote Prescribing] Consent form loaded',
);

const setPaymentResultDisplayed = createAction(
  '[Policy] Set Payment Result Shown',
  props<{paymentResultDisplayed: boolean}>(),
);

const updateExtraSupportQuestionsAndAnswers = createAction(
  '[Policy Holder] Update Extra Support Questions Answers',
  props<{extraSupportQuestionsAnswers: ExtraSupportQuestionAnswers}>(),
);

const updateExtraSupportQuestionsAndAnswersSuccess = createAction(
  '[Policy Holder] Update Extra Support Questions Answers Success',
  props<{extraSupportQuestionsAnswers: ExtraSupportQuestionAnswer[]}>(),
);

const updateExtraSupportQuestionsAndAnswersFailure = createAction(
  '[Policy Holder] Update Extra Support Questions Answers Failure',
);

const fetchExtraSupportQuestionsAndAnswers = createAction(
  '[Policy Holder] Fetch Extra Support Questions Answers',
);

const fetchExtraSupportQuestionsAndAnswersSuccess = createAction(
  '[Policy Holder] Fetch Extra Support Questions Answers Success',
  props<{extraSupportQuestionsAnswers: ExtraSupportQuestionAnswer[]}>(),
);

const fetchExtraSupportQuestionsAndAnswersFailure = createAction(
  '[Policy Holder] Fetch Extra Support Questions Answers Failure',
);

const extraSupportSetInitialState = createAction(
  '[Policy Holder] Extra Support Set Initial State',
);

export const policyHolderActions = {
  setPolicyHolder,
  fetchPolicyHolder,
  fetchPolicySummary,
  fetchPolicySummarySuccess,
  fetchPolicySummaryFailure,
  searchAddress,
  searchAddressSuccess,
  retrieveAddress,
  retrieveAddressSuccess,
  accountDetailsPageLoaded,
  updatePhoneNumbers,
  updatePhoneNumbersSuccess,
  updatePhoneNumbersError,
  editingField,
  editingPhoneField,
  resetEditingField,
  editAddressSelected,
  addressTypeahead,
  addressManual,
  britishForcesPostcodeSelected,
  changeNotAllowed,
  changeNotAllowedError,
  recalculatePremium,
  recalculationSuccess,
  recalculationError,
  updateAddress,
  updateAddressSuccess,
  updateAddressError,
  addressChangeCancelled,
  addressSearchResultSelected,
  manualAddressFormSubmitted,
  fetchPersonalDetailsSuccess,
  fetchPersonalDetailsFailure,
  fetchRestrictionsSuccess,
  fetchRestrictionsError,
  fetchConsents,
  fetchConsentsSuccess,
  fetchConsentsError,
  patchConsents,
  patchConsentsSuccess,
  patchConsentsFailure,
  remotePrescribingConsentFormLoaded,
  setPaymentResultDisplayed,
  updateExtraSupportQuestionsAndAnswers,
  updateExtraSupportQuestionsAndAnswersSuccess,
  updateExtraSupportQuestionsAndAnswersFailure,
  fetchExtraSupportQuestionsAndAnswersSuccess,
  fetchExtraSupportQuestionsAndAnswersFailure,
  extraSupportSetInitialState,
  fetchExtraSupportQuestionsAndAnswers,
};
