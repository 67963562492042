import { Action, createReducer, on } from '@ngrx/store';
import { policyHolderActions } from '../actions/policy-holder.actions';
import { initialPolicyHolderState, PolicyHolderState } from '../state';
import { PolicyPaymentFrequency } from '@features/policies/enums/policy-payment-frequency.enum';
import {
  isHorse, 
  isLimitedAccess,
  isRider,
  isMonthly,
  getPolicyName,
  isPrizeDrawWinner,
} from '@features/policies/services/policy-methods.service';
import { PhoneEditStates } from '@features/account-details/enums/phone-edit-states.enum';
import { ClientDetailsEnum } from '@features/account-details/enums/client-details.enum';
import { AddressEditState } from '@features/account-details/enums/address-edit-state.enum';
import { coreActions } from '@core/store/actions/core.actions';
import { ExtraSupportEditState } from '@features/account-details/enums/extra-support-edit-state.enum';

const createPolicyHolderReducer = createReducer(

  initialPolicyHolderState,

  on(policyHolderActions.setPolicyHolder, (state, props): PolicyHolderState => ({
    ...state,
    policyHolder: props.policyHolder,
  })),

  on(policyHolderActions.fetchPolicySummarySuccess, (state, props): PolicyHolderState => ({
    ...state,
    policyHolder: props.policyHolder,
    hasAnyActivePolicy: props.activePolicies?.length > 0,
    hasActiveLiveAnnualPolicy: props.activePolicies?.filter(x =>
      x.status === 'Live' && x.paymentFrequency === PolicyPaymentFrequency.Annually).length > 0,
    hasActiveHorseRiderPolicy: props.activePolicies?.filter(x =>
      x.status === 'Live' && (isHorse(x) || isRider(x))).length > 0,
    hasActiveLimitedAccessPolicy: props.activePolicies?.filter(x =>
      x.status === 'Live' && isLimitedAccess(x)).length > 0,
    hasMultipleActiveMonthlyPolicies: props.activePolicies?.filter(x =>
      x.status === 'Live' && isMonthly(x)).length > 1,
    petNames: props.activePolicies?.length > 0
      ? props.activePolicies.map(
        x => ({
          name: getPolicyName(x),
          monthlyPolicy: isMonthly(x),
          prizeDrawWinner: isPrizeDrawWinner(x),
        }))
        .filter(x => !!x.name)
      : [], 
  })),
  
  on(policyHolderActions.searchAddressSuccess, (state, props): PolicyHolderState => ({
    ...state,
    searchAddress: props.addressSearchResponse,
  })),

  on(policyHolderActions.retrieveAddressSuccess, (state, props): PolicyHolderState => ({
    ...state,
    editingStatus: props.retrieveAddressResult.postCode.toUpperCase().startsWith('BF')
      ? AddressEditState.PostCodeNotAllowed
      : state.editingStatus,
    retrieveAddress: props.retrieveAddressResult,
  })),

  on(policyHolderActions.editingPhoneField,
    (state): PolicyHolderState => ({
      ...state,
      editing: ClientDetailsEnum.Mobile,
      editingStatus: PhoneEditStates.Editing,
    }),
  ),

  on(policyHolderActions.updatePhoneNumbers, 
    (state): PolicyHolderState => ({
      ...state,
      editingStatus: PhoneEditStates.Submitting,
    })),

  on(policyHolderActions.updatePhoneNumbersSuccess, 
    (state, props): PolicyHolderState => ({
      ...state,
      editingStatus: PhoneEditStates.Success,
      policyHolder: { ...props.policyHolder },
    })),

  on(policyHolderActions.updatePhoneNumbersError,
    (state): PolicyHolderState => ({
      ...state,
      editingStatus: PhoneEditStates.Error,
    }),
  ),

  on(policyHolderActions.editingField, (state, props): PolicyHolderState => ({
    ...state,
    editing: props.field,
    premiumRecalculation: null,
  })),

  on(policyHolderActions.resetEditingField,
    policyHolderActions.accountDetailsPageLoaded,
    policyHolderActions.addressChangeCancelled,
    (state): PolicyHolderState => ({
      ...state,
      editing: null,
      editingStatus: null,
      premiumRecalculation: null,
    })),

  on(policyHolderActions.editAddressSelected,
    (state): PolicyHolderState => ({
      ...state,
      editing: ClientDetailsEnum.Address,
      editingStatus: AddressEditState.Loading,
      retrieveAddress: null,
      searchAddress: null,
      premiumRecalculation: null,
    }),
  ),

  on(policyHolderActions.addressTypeahead,
    (state): PolicyHolderState => ({
      ...state,
      editingStatus: AddressEditState.TypeaheadEdit,
      retrieveAddress: null,
      searchAddress: null,
    }),
  ),

  on(policyHolderActions.addressManual,
    (state): PolicyHolderState => ({
      ...state,
      editingStatus: AddressEditState.ManualEdit,
      retrieveAddress: null,
      searchAddress: null,
    }),
  ),

  on(policyHolderActions.britishForcesPostcodeSelected,
    (state): PolicyHolderState => ({
      ...state,
      editingStatus: AddressEditState.PostCodeNotAllowed,
    }),
  ),

  on(policyHolderActions.changeNotAllowed,
    (state): PolicyHolderState => ({
      ...state,
      editingStatus: AddressEditState.ChangeNotAllowed,
    }),
  ),

  on(policyHolderActions.changeNotAllowedError,
    (state): PolicyHolderState => ({
      ...state,
      editingStatus: AddressEditState.Error,
    }),
  ),

  on(policyHolderActions.recalculatePremium,
    (state): PolicyHolderState => ({
      ...state,
      editingStatus: AddressEditState.Loading,
    }),
  ),

  on(policyHolderActions.recalculationError,
    (state): PolicyHolderState => ({
      ...state,
      editingStatus: AddressEditState.Error,
    }),
  ),

  on(policyHolderActions.recalculationSuccess,
    (state, { premiumRecalculation }): PolicyHolderState => ({
      ...state,
      editingStatus: state.hasActiveLiveAnnualPolicy
        ? AddressEditState.AnnualReview
        : AddressEditState.MonthlyReview,
      premiumRecalculation,
    }),
  ),

  on(policyHolderActions.manualAddressFormSubmitted,
    (state): PolicyHolderState => ({
      ...state,
      editingStatus: state.hasActiveLiveAnnualPolicy
        ? AddressEditState.AnnualReview
        : AddressEditState.MonthlyReview,
    }),
  ),

  on(policyHolderActions.updateAddressError,
    (state): PolicyHolderState => ({
      ...state,
      editingStatus: AddressEditState.Error,
    }),
  ),
  
  on(policyHolderActions.updateAddressSuccess, 
    (state, { newAddress }): PolicyHolderState => ({
      ...state,
      editingStatus: AddressEditState.Success,
      policyHolder: { 
        ...state.policyHolder,
        building: newAddress.clientAddress1,
        street: newAddress.clientAddress2,
        town: newAddress.clientAddress3,
        city: newAddress.clientAddress4,
        county: newAddress.clientAddress5,
        postcode: newAddress.clientPostCode,
      },
    })),

  on(coreActions.resetStore, 
    (): PolicyHolderState => ({
      ...initialPolicyHolderState,
    })),

  on(policyHolderActions.fetchPersonalDetailsSuccess, 
    (state, { isJoiiRegistered }): PolicyHolderState => ({
      ...state,
      isJoiiRegistered,
    })),

  on(
    policyHolderActions.fetchRestrictionsSuccess,
    (state, { restrictions }): PolicyHolderState => ({
      ...state,
      restrictions,
    }),
  ),

  on(
    policyHolderActions.fetchConsentsSuccess, 
    (state, { consents }): PolicyHolderState => ({
      ...state,
      consents,
    }),
  ),

  // When we have more than one consent type, this will break
  // Please fix by only updating the explicitly specified patched consents.
  // Or returning all consents back in the patch response.
  on(
    policyHolderActions.patchConsentsSuccess, 
    (state, { consents }): PolicyHolderState => ({
      ...state,
      consents,
    }),
  ),

  // Added this in policy holder state as the policy state gets reset during the rehydration process
  // We will need to fix the Policy state rehydration issue and might move this to policy state.
  on(
    policyHolderActions.setPaymentResultDisplayed,
    (state, { paymentResultDisplayed }): PolicyHolderState => ({
      ...state,
      paymentResultDisplayed,
    }),
  ),

  on(
    policyHolderActions.updateExtraSupportQuestionsAndAnswers, 
    (state): PolicyHolderState => ({
      ...state, 
      extraSupportEditState: ExtraSupportEditState.Submitting,
    }),
  ),

  on(
    policyHolderActions.updateExtraSupportQuestionsAndAnswersSuccess, 
    (state, {extraSupportQuestionsAnswers}): PolicyHolderState => ({
      ...state, 
      extraSupportEditState: ExtraSupportEditState.Submitted,
      extraSupportQuestionsAnswers,
    }),
  ),

  on(
    policyHolderActions.updateExtraSupportQuestionsAndAnswersFailure, 
    (state): PolicyHolderState => ({
      ...state, 
      extraSupportEditState: ExtraSupportEditState.Error,
    }),
  ),

  on(
    policyHolderActions.fetchExtraSupportQuestionsAndAnswersSuccess, 
    (state, {extraSupportQuestionsAnswers}): PolicyHolderState => ({
      ...state, 
      extraSupportEditState: ExtraSupportEditState.Initial,
      extraSupportQuestionsAnswers,
    }),
  ),

  on(
    policyHolderActions.fetchExtraSupportQuestionsAndAnswersFailure, 
    (state): PolicyHolderState => ({
      ...state, 
      extraSupportEditState: ExtraSupportEditState.Initial,
    }),
  ),

  on(policyHolderActions.extraSupportSetInitialState,
    (state): PolicyHolderState => ({
      ...state,
      extraSupportEditState: ExtraSupportEditState.Initial,
    })),

  on(policyHolderActions.fetchExtraSupportQuestionsAndAnswers,
    (state): PolicyHolderState => ({
      ...state,
      extraSupportEditState: ExtraSupportEditState.Loading,
    })),
);

export const policyHolderReducer = (state: PolicyHolderState | undefined, action: Action) =>
  createPolicyHolderReducer(state, action);
