import {
  CommunicationPreferences,
} from "@features/communication-preferences/interfaces/communication-preferences.interface";

export const CommunicationPreferencesStateName = 'CommunicationPreferences';

export interface CommunicationPreferencesState {
  communicationPreferences: CommunicationPreferences;
  initialCommunicationPreferences: CommunicationPreferences;
  communicationPreferencesChanged: boolean;
  success: boolean;
  error: string | null;
  saving: boolean;
  loading: boolean;
  hasContactFormatChanged: boolean;
}

export const initialCommunicationPreferencesState: CommunicationPreferencesState = {
  communicationPreferences: null,
  initialCommunicationPreferences: null,
  communicationPreferencesChanged: false,
  success: false,
  error: null,
  saving: false,
  loading: false,
  hasContactFormatChanged: false,
};
