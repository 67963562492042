import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { of } from 'rxjs';
import { catchError, delay, filter, map, switchMap, tap } from 'rxjs/operators';
import { PolicyService } from '@features/policies/services/policy.service';
import { policyHolderActions } from '../actions/policy-holder.actions';
import { Store } from '@ngrx/store';
import { policyHolderSelectors } from '../selectors/policy-holder.selectors';
import { AddressLookupService } from '@shared/services/address-lookup.service';
import { AddressSearchResponse } from '@shared/interfaces/address-search-response.interface';
import { RenewalActions } from '@features/change-cover/store/actions/renewal.actions';
import { ToastService } from '@shared/ui/components/toast/toast.service';
import { ClientService } from '@features/account-details/services/client.service';
import { ClientFactors } from '@features/account-details/enums/client-factor.enum';
import { policyPatchResponse } from '@features/policies/interfaces/policy-patch-response.interface';
import {
  RetrieveAddressResult,
} from '@features/account-details/components/Interfaces/address-retrieve-result.interface';
import {
  PolicyPremiumRecalculationService,
} from '@features/policies/services/policy-premium-recalculation.service';
import {
  ClientPremiumRecalculation,
} from '@features/policies/interfaces/client-premium-recalculation.interface';
import { ToastType } from '@shared/ui/enums/toast-type';
import { TranslateService } from '@ngx-translate/core';
import { FeatureFlagService } from '@shared/feature-flags/services/feature-flag.service';
import { FeatureFlags } from '@shared/enums/feature-flags.enum';

@Injectable()
export class PolicyHolderEffects {

  fetchPolicySummary$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        policyHolderActions.fetchPolicySummary,
        policyHolderActions.accountDetailsPageLoaded,
        RenewalActions.startRenewalJourney,
        policyHolderActions.remotePrescribingConsentFormLoaded,
      ),
      switchMap(() =>
        this.policyService.getPagedPolicies(100, 0, true, '').pipe(
          map((policySummary) => 
            policyHolderActions.fetchPolicySummarySuccess(
              {
                policyHolder: policySummary.policyHolder,
                activePolicies: policySummary.policies,
              },
            ),
          ),
          catchError(() => of(policyHolderActions.fetchPolicySummaryFailure())),
        ),
      ),
    ); },
  );

  retrieveAddress$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(policyHolderActions.retrieveAddress),
      switchMap(({ addressId }) =>
        this.addressLookupService.retrieveAddress(addressId).pipe(
          map((retrieveAddressResult: RetrieveAddressResult[]) =>
            policyHolderActions
              .retrieveAddressSuccess({ retrieveAddressResult: retrieveAddressResult[0] }),
          ),
        ),
      ),
    );
  });

  searchAddress$ = createEffect(() => { 
    return this.actions$.pipe(
      ofType(policyHolderActions.searchAddress),
      switchMap(({ text, container }) =>
        this.addressLookupService.search(text, container).pipe(
          map((addressSearchResponse: AddressSearchResponse[]) =>
            policyHolderActions.searchAddressSuccess({ addressSearchResponse })),
        ),
      ),
    );
  });

  addressSearchResultSelected$ = createEffect(() => { 
    return this.actions$.pipe(
      ofType(policyHolderActions.addressSearchResultSelected),
      map(({ addressSearchResponse }) => {
        if (addressSearchResponse.type === 'Address') {
          return policyHolderActions.retrieveAddress({ addressId: addressSearchResponse.id });
        } else {
          return policyHolderActions.searchAddress(
            { text: addressSearchResponse.text, container: addressSearchResponse.id },
          );
        }
      }),
    );
  });

  updatePhoneNumbers$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        policyHolderActions.updatePhoneNumbers,
      ),
      concatLatestFrom(() => [
        this.store.select(policyHolderSelectors.selectHomeNumber),
      ]),
      switchMap(([x, homeNumber]) =>
        this.policyService
          .updateContactNumbers(
            x.mobileNumber,
            homeNumber,
            x.eveningNumber,
          )
          .pipe(
            map((policyHolder) => 
              policyHolderActions.updatePhoneNumbersSuccess({ policyHolder })),
            catchError(() => 
              of(policyHolderActions.updatePhoneNumbersError())),
          ),
      ),
    );},
  );

  showSuccessMessages$ =  createEffect(() => {
    return this.actions$.pipe(
      ofType(
        policyHolderActions.updatePhoneNumbersSuccess,
        policyHolderActions.updateAddressSuccess,
      ),
      tap(() =>
      {
        this.toastService.toasts = [];
        this.toastService.show(
          'Changes have been successful, this may take a few minutes to show.',
          {
            classname: 'text-dark',
          });
      }),
      switchMap(() => of(policyHolderActions.resetEditingField()).pipe(delay(5000))),
    );
  },
  );

  editAddress$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(policyHolderActions.editAddressSelected),
      switchMap(() =>
        this.clientService.getChangeAllowed(ClientFactors.Address).pipe(
          map((changeAllowed) => 
            changeAllowed
              ? policyHolderActions.addressTypeahead()
              : policyHolderActions.changeNotAllowed(),
          ),
          catchError(() => of(policyHolderActions.changeNotAllowedError())),
        ),
      ),
    );
  });

  updateAddress$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(policyHolderActions.updateAddress),
      concatLatestFrom(() => 
        this.store.select(policyHolderSelectors.selectPolicyHolder),
      ),
      switchMap(([{ newAddress }, policyHolder]) =>
        this.clientService.updateAddress({
          newAddress,
          oldAddress: {
            building: policyHolder.building,
            street: policyHolder.street,
            town: policyHolder.town,
            city: policyHolder.city,
            county: policyHolder.county,
            postcode: policyHolder.postcode,
          },
        }).pipe(
          tap((x: policyPatchResponse) => {
            if (x.createdId === 0) {
              throw `updateAddress failed, message: ${x.errorMessage}`;
            }
          }),
          map(() => policyHolderActions.updateAddressSuccess({
            newAddress,
          })),
          catchError(() => of(policyHolderActions.updateAddressError())),
        ),
      ),
    );
  });

  cancelAddressChange$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(policyHolderActions.addressChangeCancelled),
      concatLatestFrom(() => [
        this.store.select(policyHolderSelectors.selectPolicyHolder),
        this.store.select(policyHolderSelectors.selectPremiumRecalculation),
      ],
      ),
      filter(([_, _policyHolder, premiumRecalculation]) => premiumRecalculation !== null),
      switchMap(([{ editedAddress }, policyHolder]) =>
        this.clientService.auditUpdateAddressCancelledChange({
          newAddress: editedAddress,
          oldAddress: {
            building: policyHolder.building,
            street: policyHolder.street,
            town: policyHolder.town,
            city: policyHolder.city,
            county: policyHolder.county,
            postcode: policyHolder.postcode,
          },
        }),
      ), 
    );
  },
  { dispatch: false });

  recalculatePremiumsForAddressChange$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(policyHolderActions.recalculatePremium),
      concatLatestFrom(() => 
        this.store.select(policyHolderSelectors.selectHasActiveLiveAnnualPolicy),
      ),
      switchMap(([{ newAddress }, hasActiveLiveAnnualPolicy]) => 
        this.premiumRecalculationService
          .getClientPremiumRecalculation({
            factor: ClientFactors.Address,
            newValue: newAddress.clientPostCode,
          }).pipe(
            map((premiumRecalculation) => {
              if (hasActiveLiveAnnualPolicy) {
                return policyHolderActions.recalculationSuccess({ premiumRecalculation });
              }

              // for clients with monthly policies we check to see if their premiums have changed
              // if they have we show the schedule, otherwise update address
              const premiumChanged = premiumRecalculation.reduce(
                (acc: boolean, cur: ClientPremiumRecalculation) => 
                  (acc || cur.premiumRecalculationDto.currentMonthlyPayment.collectionAmount
                    !== cur.premiumRecalculationDto.newMonthlyPayment.collectionAmount),
                false);
              if (premiumChanged) {
                return policyHolderActions.recalculationSuccess({ premiumRecalculation });
              } else {
                return policyHolderActions.updateAddress({ newAddress });
              }
            }),
            catchError(() => of(policyHolderActions.recalculationError())),
          ),
      ),
    );
  });

  fetchRestrictions$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        policyHolderActions.accountDetailsPageLoaded,
        policyHolderActions.remotePrescribingConsentFormLoaded,
      ),
      switchMap(() =>
        this.clientService.getRestrictions().pipe(
          map((restrictions) =>
            policyHolderActions.fetchRestrictionsSuccess({
              restrictions,
            }),
          ),
        ),
      ),
      catchError(() =>
        of(policyHolderActions.fetchRestrictionsError()),
      ),
    );
  });
  
  fetchConsents$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        policyHolderActions.remotePrescribingConsentFormLoaded,
      ),
      concatLatestFrom(_ =>
        this.featureFlagService.isFeatureEnabled(FeatureFlags.JoiiRemotePrescribingConsent)),
      filter(([_, isEnabled]) => isEnabled),
      switchMap(() =>
        this.clientService.getConsents().pipe(
          map((consents) =>
            policyHolderActions.fetchConsentsSuccess({
              consents,
            }),
          ),
        ),
      ),
      catchError(() =>
        of(policyHolderActions.fetchConsentsError()),
      ),
    );
  });

  patchConsents$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        policyHolderActions.patchConsents,
      ),
      switchMap((props) =>
        this.clientService.patchConsents(props.consents).pipe(
          map((consents) => policyHolderActions.patchConsentsSuccess({
            consents: consents,
          })),
        ),
      ),
      catchError(() => of(policyHolderActions.patchConsentsFailure())),
    );
  });
  
  patchConsentsSuccessToast$ =  createEffect(() => {
    return this.actions$.pipe(
      ofType(
        policyHolderActions.patchConsentsSuccess,
      ),
      concatLatestFrom(
        _ => this.store.select(policyHolderSelectors.selectPetCount),
      ),
      tap(([patchConsentsSuccessProps, petCount]) =>
      {
        this.toastService.toasts = [];
        this.toastService.show(
          this.translateService.instant(
            patchConsentsSuccessProps.consents.joiiRemotePrescribing
              ? 'AUTH.POLICY.JOII.REMOTEPRESCRIBING.SAVECONSENTED'
              : 'AUTH.POLICY.JOII.REMOTEPRESCRIBING.SAVENOTCONSENTED',
            { PETS: petCount },
          ),
          {
            classname: patchConsentsSuccessProps.consents.joiiRemotePrescribing
              ? 'fw-bold text-dark my-2 w-100 toast-success'
              : 'fw-bold text-dark my-2 w-100 toast-warning',
            toastType: patchConsentsSuccessProps.consents.joiiRemotePrescribing
              ? ToastType.Success
              : ToastType.Warning,
          });
      }),
    );
  }, { dispatch: false });

  updateExtraSupportQuestionsAndAnswers$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(policyHolderActions.updateExtraSupportQuestionsAndAnswers),
      switchMap((props) =>
        this.clientService.updateExtraSupportQuestionsAndAnswers(props.extraSupportQuestionsAnswers)
          .pipe(
            map((questions) => {
              return policyHolderActions.updateExtraSupportQuestionsAndAnswersSuccess({
                extraSupportQuestionsAnswers: questions,
              });
            }),
            catchError(() => 
              of(policyHolderActions.updateExtraSupportQuestionsAndAnswersFailure()),
            ),
          ),
      ), 
    );
  });
  

  fetchExtraSupportQuestionsAndAnswers$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        policyHolderActions.fetchExtraSupportQuestionsAndAnswers,
      ),
      switchMap(() =>
        this.clientService.getExtraSupportQuestionsAndAnswers()
          .pipe(
            map((questions) => {
              return policyHolderActions.fetchExtraSupportQuestionsAndAnswersSuccess({
                extraSupportQuestionsAnswers: questions,
              });
            }),
            catchError(() => of(policyHolderActions.fetchExtraSupportQuestionsAndAnswersFailure())),
          ),
      ),
    );
  });

  constructor(
    private policyService: PolicyService,
    private addressLookupService: AddressLookupService,
    private actions$: Actions,
    private store: Store,
    private toastService: ToastService,
    private clientService: ClientService,
    private premiumRecalculationService: PolicyPremiumRecalculationService,
    private translateService: TranslateService,
    private featureFlagService: FeatureFlagService,
  ) {}
}
